/* Footer Styles */
.footer {
    width: 100%;
    background: #b6b6b6;
    padding: 15px 0;
    text-align: center;
    margin: 0px 20px 10px 0px;
    box-shadow: 5px 10px 10px 0px  rgba(138, 49, 49, 0.1);

  }
  
  .footer-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .footer-icon {
    color: #5A3E1B;
    font-size: 26px;
    transition: 0.3s ease-in-out;
  }
  
  .footer-icon.instagram:hover {
    color: #833AB4;
    transform: scale(1.2);
  }
  
  .footer-icon.twitter:hover {
    color: #000000;
    transform: scale(1.2);
  }
  
  .footer-icon.reddit:hover {
    color: #FF4500;
    transform: scale(1.2);
  }
  
  .footer-icon.gmail:hover {
    color: #D14836;
    transform: scale(1.2);
  }
  
  .footer-icon.youtube:hover {
    color: #FF0000;
    transform: scale(1.2);
  }