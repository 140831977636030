
/* 📦 Form Container */
.container {
    width: 600px;
    min-height: 300px;
    max-height: 600px;
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
}



/* 📝 Input Fields */
input,
select,
textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 2px solid #8B4513;
    border-radius: 6px;
    background: #FFF3CD;
    font-size: 14px;
}




/* ✅ Buttons */
.button-container {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
}

/* 🎮 Navigation & Submit Buttons */
.nav-btn, .send-btn {
    padding: 10px 20px;
    width: 130px;
    text-align: center;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: 0.3s;
}

.nav-btn {
    background: #f4a261;
    color: white;
}

.send-btn {
    background: #ff1744;
    color: white;
    font-weight: bold;
    border: 2px solid #b71c1c;
}

/* 🎯 Hover Effects */
.nav-btn:hover, .send-btn:hover {
    transform: scale(1.05);
}

/* 📱 Responsive */
@media (max-width: 650px) {
    .container {
        width: 95%;
        max-height: 500px;
    }
}
