.feedback-container {
  text-align: center;
  margin-top: 20px;
}

.feedback-card {
  display: flex;
  align-items: center;
  background: #fffdfd;
  padding: 11px;
  border-radius: 10px;
  margin: 15px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.feedback-content {
  text-align: left;
}

.user-name {
  font-size: 16px;
  font-weight: bold;
}

.rating {
  display: flex;
  gap: 3px;
  color: gold;
}

.star-icon {
  font-size: 16px;
}

.feedback-text {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
}