.story-container {
    background-color: #fff;
    padding: 25px;
    border-radius: 15px;
    margin: auto;
    box-shadow: 5px 10px 10px 1px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.story-title {
    font-size: 24px;
    font-weight: bold;
}

.story-content {
    font-size: 18px;
    color: #333;
    text-align: left;
    margin-top: 10px;
}

.highlight {
    font-weight: bold;
    color: black;
}

.story-link {
    text-align: center;
    margin-top: 15px;
    font-style: italic;
    color: rgb(47, 183, 236);
}

.story-link a {
    text-decoration: none;
    color: rgb(47, 183, 236);
}

.story-link a:hover {
    text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
    .story-container {
        max-width: 90%;
        padding: 20px;
    }

    .story-title {
        font-size: 20px;
    }

    .story-content {
        font-size: 16px;
    }
}
