.otp-card {
  width: 24rem;
  border-radius: 12px;
  background: white;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.otp-input {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  text-align: center;
  border: 2px solid #e6860a;
  border-radius: 8px;
  outline: none;
  transition: 0.2s ease-in-out;
}

.otp-input:focus {
  border-color: #e6860a;
}

.btn-primary {
  background-color: #e6860a;
  border: none;
}

.btn-primary:hover {
  background-color: #e6860a;
}

.btn-success {
  background-color: #28a745;
  border: none;
}

.btn-success:hover {
  background-color: #218838;
}

.enter-text{
  color: #ff7b00 ;
  font-size: 20px;
}
.send-otp-btn {

  background-color: #ff7b00 !important;
  border: none;
color: white;
}

.bg-light {
  background: rgba(148, 145, 145, 0.8) !important ;
}

.form-control {
  border: 2px solid #e6860a !important;
  box-shadow: none !important;
  outline: none !important;
}

.form-control:focus {
  border: 2px solid #e6860a !important;
  box-shadow: none !important;
}

.close-icon {
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 20px;
  cursor: pointer;
  color: gray;
}

.close-icon:hover {
  color: black;
}
