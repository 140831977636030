.splash-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black transparent ; /* Extra safety for black background */
}

/* Video Styling */
.splash-video {
    width: 100%;
    height: 100%;
    object-fit: contain; /* ✅ Ensures full video is visible */
}
