.custom-card {
  border-radius: 15px;
  overflow: hidden;
  text-align: center;
  width: 100%;
  max-width: 1000px;
  background-color: rgb(239 238 238);;
  /* background-image: url("https://tse2.mm.bing.net/th/id/OIP.vflclr4yRBpju0nL7ImkvQHaFj?w=198&h=180&c=7&r=0&o=5&pid=1.7");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  margin: auto;
  border: none;
}

/* Image Centering */
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
}

/* Dish Name */
.dish-name {
  font-size: 25px;
  font-weight: bold;
  color: black;
  text-align: center;
}

/* Hotel Name & Address */
.hotel-name,
.address,
.location {
  font-size: 18px;
  color: gray;
  font-weight: 500;
  text-align: center;
}

/* Description */
.hotel-description {
  font-size: 16px;
  color: white;
  font-weight: 400;
  text-align: center;
}

/* Button Group Alignment */
.button-group {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
}

.vote-btn,
.direction-btn {
  font-size: 16px;
  padding: 10px 20px;
}

/* Centering Total Votes Button */
.vote-count-btn {
  background-color: orange;
  font-size: 18px;
  font-weight: bold;
  border-radius: 15px;
  margin: 15px auto;
  width: 50%;
  display: block;
  padding: 10px;
  border: none;
  text-align: center;
}