/*Full Map Container */
.map-container {
    height: 100vh;
    width: 100%;
    position: relative;
}

/*Leaflet Map Full Size */
.leaflet-container {
    height: 100%;
    width: 100%;
}

/*Popup Container */
.popup-container {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px;
    text-align: center;
    z-index: 1000;
}

.directions-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 6px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .directions-btn:hover {
    background-color: #0056b3;
  }

.landmark-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
}

.popup-container button {
    background: #ff5733;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.popup-container button:hover {
    background: #d43f00;
}

.food-card-container {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    background: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  }

  /* 🔙 Back Button */
.back-button {
    position: absolute;
    top: 15px;
    left: 45px;
    background: #FF5722;
    color: white;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000; /* Ensures it's always on top */
    transition: 0.3s ease-in-out;
}

/* 🎯 Hover Effect */
.back-button:hover {
    background: #E64A19;
    transform: scale(1.05);
}

/* 📱 Mobile View */
@media (max-width: 650px) {
    .back-button {
        font-size: 14px;
        padding: 6px 12px;
    }
}


/*Responsive Fixes */
@media screen and (max-width: 768px) {
    .popup-container {
        width: 90%;
    }

    .landmark-image {
        height: 150px;
    }
}