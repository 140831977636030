/* Center Image */
.network-image {
  display: block;
  margin-bottom: 10px;
}

/* Google Sign-In Button */
.google-logo {
  width: 20px;
  height: 20px;
}

/* Card Styling */
.card {
  max-width: 400px;
  width: 100%;
  border-radius: 12px;
  text-align: center;
  background-color: white;
}

.sign-btn {
  color: rgb(65, 176, 176) !important;
  font-size: 18px;
  font-weight: 500;
  padding: 10px;
  border-color:rgb(65, 176, 176) !important ;
  border-radius: 8px;
}

/* .sign-btn:hover {
  background:  rgb(65, 176, 176);
} */

/* Toast Notification Styling */
.Toastify__toast {
  font-size: 16px;
  font-weight: bold;
}


.card-title{

  font-size: 20px;
  color: rgb(65, 176, 176);
}
