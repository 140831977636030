.food-card {
  position: relative;
  background: white;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  max-width: 400px;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #333;
}

.food-title {
  font-size: 22px;
  font-weight: bold;
  color: #222;
  margin-bottom: 5px;
}

.restaurant-name {
  font-size: 16px;
  color: rgb(87, 86, 86);
}

.city-state {
  font-size: 14px;
  color: rgb(128, 128, 128);
  margin-bottom: 8px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.food-img {
  width: 100%;
  max-width: 250px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

/* Vote Section */
.vote-section {
  background-color: #ffc107;
  color: black;
  border-radius: 20px;
  padding: 6px 15px;
  text-align: center;
  width: 100%;
  max-width: 250px;
}

.vote-section h6 {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.vote-section p {
  margin: 0;
  font-size: 12px;
  font-style: italic;
  color: #4e4e4e;
}

/* Buttons Container */
.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
}

.button-container button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  padding: 10px;
  border-radius: 8px;
  flex-grow: 1;
}

.vote-btn {
  background: #28a745;
  color: white;
  border: none;
}

.direction-btn {
  background: #ff9800;
  color: white;
  border: none;
}

.details-btn {
  background: #17a2b8;
  color: white;
  border: none;
  width: 100%;
  margin-top: 10px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .food-card {
    max-width: 90%;
    padding: 12px;
  }

  .image-container {
    width: 100%;
  }

  .food-img {
    max-width: 220px;
  }

  .button-container {
    flex-direction: column;
    gap: 8px;
  }

  .button-container button {
    width: 100%;
  }
}
