
/* Navbar */
.navbar {
    background-color: white;
    padding: 10px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Navbar Container */
.nav-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Navbar Links */
.nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
    padding: 0;
    font-size: 18px;
}

.nav-links li {
    display: inline;
}

/* Icons & Text Styling */
.nav-links a {
    text-decoration: none;
    color: black;
    font-weight: 500;
    font-size: 17px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 8px; /* Space between icon & text */
}   

/* Icon Default Color */
.nav-icon {
    color: #ff5733;  /* Default Orange */
    font-size: 20px;
    transition: color 0.3s ease;
}

/* Hover Effect */
.nav-links a:hover {
    color: #007bff;
}

.nav-links a:hover .nav-icon {
    color: #007bff; /* Blue on hover */
}
