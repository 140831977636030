.VoteCard-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    /* max-width: 1100px; */
    margin: auto;
    width: 100%;
}

.title {
    font-size: 25px;
    font-weight: 600;
}

.discription {
    padding-top: 25px;
    font-size: 20px;
}
.vote-btn {
    background: #28a745 !important;
    color: white !important;
    border: none !important;
    font-weight: 600;
    border-radius: 30px;
    margin: 10px;
    width: 20%;
    font-size: 20px;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 110%;
    height: 100vh;
    /* background: rgba(0, 0, 0, 0.8); Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        text-align: center;
    }
    .vote-btn {
        width: 60%;  /* Adjust width for smaller screens */
        font-size: 18px;
        padding: 8px 15px;
    }
}

@media (max-width: 480px) {
    .vote-btn {
        width: 80%;  /* Full width for extra small screens */
        font-size: 16px;
        padding: 6px 12px;
    }
}