.food-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  .custom-vote-section {
    background-color: rgba(248, 160, 88, 0.3);
    border-radius: 12px;
    padding: 0px 46px;
    text-align: center;
    font-size: 16px;
    width: fit-content;
    margin: 10px auto;
    font-weight: 500;
    display: inline-block;
}
  
  .custom-vote-section b {
    font-weight: bold;
    color: black; /* Total votes - dark */
  }
  
  .custom-vote-section br + b {
    color: rgba(0, 0, 0, 0.7); /* Local votes - lighter */
    font-weight: 400; /* Fainter */
  }
  